/* ReviewSlider.module.css */
.swiperContainer {
  width: 975px;
  height: 470px;
  padding: 10px 62px;
  position: relative;
  background-color: #ffffff;
  @media (max-width: 800px) {
    width: 620px;
  }
}

.swiperSlide {
  margin-top: 5px;

  width: 342px;
  height: 440px;
  display: flex;
  padding: 0px;
  align-items: flex-start;
  gap: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.sliderControler {
  opacity: 1;
  width: 575px;
  height: 100px;
  position: fixed;
  z-index: 999;
  top: 36%;
  left: 25.5%;
}

.sliderArrow {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  margin: 0 30px;
}

.sliderArrow::after {
  content: "";
}

.swiperPagination {
  opacity: 0;
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiperPaginationBullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiperPaginationBulletActive {
  background: var(--primary);
}

/* Global classes for navigation buttons */
.swiper-button-prev {
  width: 200px;
  height: 200px;
}

.swiper-button-next {
  width: 200px;
  height: 200px;
}
