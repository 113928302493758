.swiper-container {
  width: 300px;
  height: fit-content;
  position: relative;
  border-radius: 15px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

/* 커스텀 내비게이션 버튼 스타일 */
.custom-prev,
.custom-next {
  background-image: url("../../../assets/icons/left_arrow.svg"); /* 원하는 이미지 경로 */
  background-size: contain;
  background-repeat: no-repeat;
  width: 13px;
  height: 22px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  cursor: pointer;
}

.custom-prev {
  left: 10px;
}

.custom-next {
  right: 10px;
  transform: translateY(-50%) rotate(180deg);
}

.custom-pagination {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
}

.swiper-pagination-bullet {
  position: relative;
  width: 5px;
  height: 5px;
  background-color: #aaa; /* 검은색으로 보이도록 */
  opacity: 1; /* 투명도 1로 설정 */
}

.swiper-pagination-bullet-active {
  background-color: #7a7a7a; /* 활성화된 bullet은 빨간색으로 설정 */
  opacity: 1;
}
